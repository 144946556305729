import Vue from 'vue'
import App from './App.vue'
import router from './router'
import Vant from 'vant'
import axios from 'axios'
import 'vant/lib/index.css';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import MetaInfo from 'vue-meta-info'
import JSEncrypt from 'jsencrypt';
import './assets/css/font.css' // 导入全局字体样式表
import Viewer from 'v-viewer'
import 'viewerjs/dist/viewer.css'


Vue.use(MetaInfo)
Vue.use(Viewer)
Vue.use(ElementUI)
Vue.use(Vant)

Vue.prototype.$http = axios
Vue.config.productionTip = false
// 全局注册 $
Vue.prototype.$ = $




new Vue({
  router,
  render: h => h(App),
}).$mount('#app')