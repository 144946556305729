import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'






Vue.use(VueRouter)

const routes = [
  // 嵌入官网
  {
    path: '/beame/RegisterNow',
    name: '/beame/RegisterNow',
    component: () => import('../views/beame/RegisterNow.vue')
  },
  {
    path: '/beame/Pricing',
    name: '/beame/Pricing',
    component: () => import('../views/beame/Pricing.vue')
  },
  {
    path: '/beame/platform',
    name: '/beame/platform',
    component: () => import('../views/beame/platform.vue')
  },
  {
    path: '/beame/Company',
    name: '/beame/Company',
    component: () => import('../views/beame/Company.vue')
  },
  {
    path: '/',
    name: '/beame/Home',
    component: () => import('../views/beame/Home.vue')
  },
  {
    path: '/beame/Login',
    name: '/beame/Login',
    component: () => import('../views/beame/Login.vue')
  },

  // 英文
  {
    path: '/zh-en/addCase/dataUploadNew',
    name: '/zh-en/addCase/dataUploadNew',
    component: () => import('../views/zh-en/addCase/dataUploadNew.vue')
  }, {
    path: '/zh-en/addCase/transparentMarkerNew',
    name: '/zh-en/addCase/transparentMarkerNew',
    component: () => import('../views/zh-en/addCase/transparentMarkerNew.vue')
  },
  {
    path: '/zh-en/addCase/transparentToothNew',
    name: '/zh-en/addCase/transparentToothNew',
    component: () => import('../views/zh-en/addCase/transparentToothNew.vue')
  }, {
    path: '/zh-en/addCase/deagonsticMesNew',
    name: '/zh-en/addCase/deagonsticMesNew',
    component: () => import('../views/zh-en/addCase/deagonsticMesNew.vue')
  }, {
    path: '/zh-en/addCase/patientInfoNew',
    name: '/zh-en/addCase/patientInfoNew',
    component: () => import('../views/zh-en/addCase/patientInfoNew.vue')
  }, {
    path: '/zh-en/personalcenter',
    name: '/zh-en/personalcenter',
    component: () => import('../views/zh-en/personalcenter.vue')
  },
  {
    path: '/zh-en/caseDetail',
    name: '/zh-en/caseDetail',
    component: () => import('../views/zh-en/caseDetail.vue')
  },
  {
    path: '/zh-en/patientListNew',
    name: '/zh-en/patientListNew',
    component: () => import('../views/zh-en/patientListNew.vue')
  },
  {
    path: '/zh-en/caseList',
    name: '/zh-en/caseList',
    component: () => import('../views/zh-en/caseList.vue')
  },
  {
    path: '/zh-en/',
    name: '/zh-en/Login',
    component: () => import('../views/zh-en/login.vue')
  },
  {
    path: '/zh-en/Home',
    name: '/zh-en/Home',
    component: () => import('../views/zh-en/Home.vue')
  },
  {
    path: '/zh-en/about',
    name: '/zh-en/About',
    component: () => import('../views/zh-en/About.vue')
  },

  // 中文
  {
    path: '/addCase/dataUploadNew',
    name: 'dataUploadNew',
    component: () => import('../views/addCase/dataUploadNew.vue')
  }, {
    path: '/addCase/transparentMarkerNew',
    name: 'transparentMarkerNew',
    component: () => import('../views/addCase/transparentMarkerNew.vue')
  },
  {
    path: '/addCase/transparentToothNew',
    name: 'transparentToothNew',
    component: () => import('../views/addCase/transparentToothNew.vue')
  }, {
    path: '/addCase/deagonsticMesNew',
    name: 'deagonsticMesNew',
    component: () => import('../views/addCase/deagonsticMesNew.vue')
  }, {
    path: '/addCase/patientInfoNew',
    name: 'patientInfoNew',
    component: () => import('../views/addCase/patientInfoNew.vue')
  }, {
    path: '/personalcenter',
    name: 'personalcenter',
    component: () => import('../views/personalcenter.vue')
  },
  {
    path: '/caseDetail',
    name: 'caseDetail',
    component: () => import('../views/caseDetail.vue')
  },
  {
    path: '/patientListNew',
    name: 'patientListNew',
    component: () => import('../views/patientListNew.vue')
  },
  {
    path: '/caseList',
    name: 'caseList',
    component: () => import('../views/caseList.vue')
  },
  {
    path: '/Login',
    name: 'Login',
    component: () => import('../views/login.vue')
  },
  {
    path: '/Home',
    name: 'Home',
    component: () => import('../views/Home.vue')
  },
  {
    path: '/about',
    name: 'About',
    component: () => import('../views/About.vue')
  },
  {
    path: '/:catchAll(.*)',
    name: 'not-found',
    component: () => import('../views/NotFound.vue')

  },

]

const router = new VueRouter({
  // mode: 'hash',
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router


//跳转页面后回到顶部
router.afterEach((to, from, next) => {
  window,
  scrollTo(0, 0);
})