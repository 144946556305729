<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
export default {};
</script>
  
<style>
html,
body {
  margin: 0;
  /* height: 100%; */
}
#app {
  /* font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center; */
  color: #323e48;
}
a {
  color: #323e48;
}
h2,
h3,
h4,
h5,
button,
a {
  font-family: "GenSenMaruGothicTW-Bold";
}

p,
span,
div {
  font-family: "GenSenMaruGothicTW-Regular";
}
</style>



