<template>
  <div>
    <div class="footer">
      <div class="firstDiv">
        <div class="div1" style="margin-top: 18px">
          <img
            src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADgAAAA4CAMAAACfWMssAAAAjVBMVEUAAAD///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////8DizOFAAAALnRSTlMALyomIhUzORlLPBw2VQtST0NYEkAfRg9bSF4EB+jM4PGdj2O1g/O9mXjTpYh12IjEYQAAAodJREFUSMeVkYmWqjAMQEsBWTqgKFi1uI7OjLPw/5/3ki6UTeBd2qRJvKccIcMIUZZlWiKCzEWUaYdZsrZ67gyNw8bIMeqSj6uCj5GKV1664RuAywS/1CXHpNRhD+ZT8CFv4/s+zHyZMeAJUR012/Q9fx5dU0AvhN1IZptoJqJ9Xzgbv+lx7DA9YfWx7rJ6zFjIrZcyC71dqp+AjVEaT4SsYEhRsOC7As5UlrBVu4CzJaxftLCcK8mpGIPrC+HowUL+Ks1V1djGBRk3IAsl+p7lVBn+vBHU1yxU4WK41OLF8UzfJtcc0eNug8rydEdIQWQvxMeYyEB0qUspBJcCZytmFHqqj1FtWWIkJKVNPmvvl46Sko0tHEp/jfcuG/WgxtHnDSmcJon2Tq0u7Z8Z8ZwW+kMenQlcQtuNL/Oqul7A0wU7lCza5Bclfi4mAPGt1aivnBLfOmT6ymP2NooSA72RW6W4B4GdmBiohaITdDlp8ysYwQExjvEU45IsP7R5VS2MOsV1QYkb97hVxpTl8/pI4i6UsLjP3Zj3JE7wzY89kxGeW2Kdo2NlOKn3PkZ5G05EnPdZa9NyssMMryCELLIBdj3z2po7IPrZPPPRHIcginw7xKFrnhvDmCCeKfd7ldQ6fHdMbOsfUCmKbD/MT/vGxiQlEjd6wXvzdW+27xKFyKJX3Iz68bDNLCWacAVEsPCRQddQPD/P8P3v76aGh5EaZ/UfUGIR2RJYYcCMy55wY8AH1laQBul+ORP8RztmMooe1541o2QGEXh9cz3JFrw+YjHlOYIM4612kvVuiISRl6TOTnPQ0WryuhE1WB4GWDslmcTL1x1rW4A2i5DmW3nzMoppOGj9A/VUom2Inb65AAAAAElFTkSuQmCC"
            style="width: 56px; height: 56px"
          />
          <span
            style="
              margin-top: 23.49px;
              font-family: 'PingFang SC';
              font-style: normal;
              font-weight: 500;
              font-size: 12px;
              line-height: 17px;
              text-align: center;
              letter-spacing: 0.01em;
              color: rgb(196, 196, 196);
            "
            ><br />68133348 <br />周一至周六/08:00-17:00</span
          >
        </div>
        <div class="div2" style="margin-top: 18px">
          <img
            src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADgAAAA4CAMAAACfWMssAAAAh1BMVEUAAAD///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////9qkf8RAAAALHRSTlMATDJJGi1QFykUVUYdEFk2DgolOSE9UkNABl0C5YHzzqFm18mxp5aLv3S8c3tm3S4AAAJUSURBVEjHlZSLmqogFEZ3XorUJlFTyKymyzRzTu//fGdvOAKNGrVUZOO/AvH7glFaKbnkiJSybcGDtbiD1sGPa7muV8unkM80lXhf5SsPfHxP8pWfdsRbLV9Am37Pb7bL5eI1lo/mQItGnEibrpdHb5Bbj09EsvGKmxeMsreIzELNUHLe36fYX8s+lmtPZlVWKf7en9OpFJqt3tGqZ+cR931wBYSersDr5BFPVQ9NmReW41PvGJggRzEqHLpnb+jkMhQLVjAEK7o1U++5mxeUpBiFW+DskebPqHdeP8Y4LJ3qqNqvEe+LIeXFRleQxZb7KaVbvfu9zC0Np6d7rGFxHEHliBhJVOTy4H2XNPiJP2ejBbA4UAehFqV63d5+9Vsc4OMbdrUY0MkgcFDBS0jdsJ/0rMrNWRVOdijed5+q6HY0Xaf6tMyhuHZEzVVo9fumOuKKYwNx7WC3sXNGcboeZxQCasteNBzmvXbAaigGEJcWmyC1S8u0I81iowyKgWihbzIhFhBtDCh60DFqIsg3Fr9oyEGWG4EHNcIrCoExogSAQBj8Yk+M4lI0PV6x6bMLFKUVjx7v2AdLIKo0bZpU8XN48od8+MGcDjIgJBZvwkFRhFOk48MFaKQI30Jw+M9iNk04rDIwsNkbMLBIoYbmptUdU89Vox81Ehx4On8R2tEJs7aXbuvaPLKeNcOaSPAy9yEhekMz8dJwGEEGPi+QME41+0ASc1CfTt3UEUzC448p6ljCM3g5V7nto5ag5qUSyfaBJM205mfBRFqTU89Kthi1/gHiicjTGGbjLQAAAABJRU5ErkJggg=="
            style="width: 56px; height: 56px"
          />
          <span
            style="
              margin-top: 23.49px;
              font-family: 'PingFang SC';
              font-style: normal;
              font-weight: 500;
              font-size: 12px;
              line-height: 17px;
              text-align: center;
              letter-spacing: 0.01em;
              text-decoration-line: underline;
              color: rgb(196, 196, 196);
            "
            ><br />hello@mybeame.com</span
          >
        </div>
        <div class="div3" style="margin-top: 18px">
          <img
            src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADgAAAA4CAMAAACfWMssAAAAk1BMVEUAAAD////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////ROyVeAAAAMHRSTlMATBYSQy1JVVA2RjE9Oj8jKhoPHQonM1kNIFJbBgLy5qOYX+J/xbSoi27Wyb2bZ3TlPqtJAAAC0klEQVRIx42V6WKqMBCFRwFBkB1cIKJoXbrz/k93Z0iTJhAu/QiTWc4pafqjYOTIWMEKhDF2PMIc0lVokB3m6V37/b6gh3YEA5uz7SdhM7YMV4ZLBdsYp62FlQ2xZCQK853srVmyo8Fn/Ynj2LeUWLRol7UcotPsk3jtW9N1za31BoOBk1rpUvJ67STXV9FNuVP1ZanK+tZpXNfaeA+SQhu4TTeguVA/F4JC/oJprnDpDLiqIpUHzfOD7IaNydiEqjPjPnZQuXZGbqom5zdrxVTEGJHvboLvXnTgkX8yjxWuU8arqjrQJ/dqp+4mqVUdXWxalnEpOE8bzyUKhTZHI2WS92nje6lyhCIRlPg2XHQSaqW6lklZSnEBVqLClZfEb2jXK01oQe6o8C9gcv49IlYflDiJUGGSQqwZ+alCx1lJ48tP+00TxpA4gYMLA233jmgxf4rLxO6Fkg8h6/UJBBovHdFsMG25j7qn/su6Eo3bbbAVZdRw+Rbbi5f2GeGI/4gmwrGihK3OZ9fTKi1+ivtACGsdu+m4biEaD97w1ltNCMHA+Sn+6vcve23Xnw0vHwNZAM46DMM1Btow8U+dgZPXy0jJVwJxOODVZHwZqkpINxKsN7geY9+d+vQgPKaQbYasTqODuiNRBizcRPhQQDa0Rod98jkueokQAIIosiOdVvedaa6rHDRa9pib6nszCJZoZNG4752UX7Aez0MgYt+3bV+nbqTxS2nbP8IECIbFiC/ha30DBfTEKwPPn4sxzWLgsMg0PU/6okL+W12YQOfHYrFSW7zKQZIYnY+bsZ3ALyzqW56MPNmJ2usDH9kMFArf+yN0oxPOSryiqio54j7duaoIlwLfTazQN3a6s9gFGGDBnC9gYOaw2CEuPQSm9FKLQpXCJIWzm6JyGPyPYu3tdnXNV40pboiLtlniyK01XD/ntnmWSeRXF6RahMnS6PoHPfHOjl2OHuEAAAAASUVORK5CYII="
            style="width: 56px; height: 56px"
          />
          <span
            style="
              margin-top: 23.49px;
              font-family: 'PingFang SC';
              font-style: normal;
              font-weight: 500;
              font-size: 12px;
              line-height: 17px;
              text-align: center;
              letter-spacing: 0.01em;
              color: rgb(196, 196, 196);
            "
            ><br />香港中環些利街2～4號 LL Tower 6樓</span
          >
        </div>
      </div>
    </div>
  </div>
</template>
  <script>
export default {
  metaInfo: {
    link: [],
  },
  components: {},
  data() {
    return {};
  },
  mounted() {},
  methods: {},
};
</script>
  <style scoped>
.footer {
  /* position: fixed; */
  height: 180px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background: #444960;
  width: 100%;
  min-width: 1248px;
}
.firstDiv {
  padding: 0 calc(50% - 624px);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-top: 20px;
}
.div1 {
  width: 30%;
  text-align: center;
}
.div2 {
  width: 30%;
  text-align: center;
}
.div3 {
  width: 30%;
  text-align: center;
}
.secondDiv {
  text-align: center;
  margin-top: -25px;
}
</style>