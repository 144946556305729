<template>
  <div>
    <div class="header_inner">
      <span class="logoImg">be@me</span>
      <div class="spanDiv">
        <div style="float: left; width: 260px">
          <span
            @click="Jump('/Home')"
            :class="route == 'Home' ? 'spanChoosen' : 'spanClass'"
            style="margin-left: 30px"
            >工作臺</span
          >
          <span
            @click="Jump('/caseList')"
            :class="route == 'caseList' ? 'spanChoosen' : 'spanClass'"
            style="margin-left: 41px"
            >病例</span
          >
          <span
            @click="Jump('/patientListNew')"
            :class="route == 'patientListNew' ? 'spanChoosen' : 'spanClass'"
            style="margin-left: 50px"
            >患者</span
          >
        </div>
        <div style="float: right; width: calc(100% - 270px); text-align: right">
          <div style="height: 60px; width: 220px; float: right">
            <span title="姓名" class="namespan">{{
              DoctorInformation.name
            }}</span>

            <img
              v-if="this.SwitchLanguage"
              @click="gotoThisPath('/zh-en/')"
              src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADQAAAA0CAMAAADypuvZAAAAP1BMVEUAAAD///////////////////////////////////////////////////////////////////////////////9Du/pqAAAAFHRSTlMAYH/fPzAQ758fII9vv4DPr0+gUKu28wAAAAGpSURBVEjHnZbdloMgDIQDAUP5Ubs77/+sW7urURcVOzc9gX5OguYQqsjnMDBe4pKypwa5JNiIg79CCgAJ+fnoXtHD5cAABnOBiHW7ZCeO4wHSpQnpKjuGgdTVmMiAIjvZupkX8EnFkSH/tg0Q1KaW/AiYnQ9gNTpM0W/MRZkzSuLKmjFW8nX7tS+wlpA0qEP68LQkB0RqgcjrIsPSBaRllXmbqRXq5G91gGmE1MqrkVkpAGkdb60CwgzhRGqV3sfg70AO8pudQukxKwPPJUgKkbxMMsIKsvWDsCsoIFNCvgdNLgXuHvSNgRiPe1AEk4aNUAfch+hzqLsNfXYQA54KHUshh0IB+R6UkXafUbGzRiAsQdl9Rh5yo6a5K0Q3mXMdyszTj/ZsgqXWdl961kG6Vkh7tsC2QwYDkVq1QQxDpFZNUA9e3jF8GxSB+NkFoEFogcbNw6Ogv4Z6SNzdIf0V1Gvp+p/x/KL+AkxtJIjHjGeIrw8f/ZFND3A8GnPYVBHRMadiVpnU3IQUR8cyAyYuuzg5xO8cBKeITmobSVLkjMupvEkeQnUc/QEdFijuTK/OWgAAAABJRU5ErkJggg=="
              style="
                width: 24px;
                height: 24px;
                transform: translateY(13px);
                cursor: pointer;
              "
            />
            <span style="margin-left: 22px">
              <el-dropdown trigger="click">
                <span class="el-dropdown-link">
                  <img
                    v-if="DoctorInformation != null && DoctorInformation != ''"
                    :src="DoctorInformation.avatar"
                    style="
                      width: 30px;
                      height: 30px;
                      transform: translateY(16px);
                    "
                  />
                  <img
                    v-else
                    src="http://static.file.eset3d.com/avatar.png"
                    style="
                      width: 30px;
                      height: 30px;
                      transform: translateY(16px);
                    "
                  />
                </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item
                    ><p @click="Jump('/personalcenter')" style="margin: 0px">
                      個人中心
                    </p></el-dropdown-item
                  >
                  <el-dropdown-item
                    ><p @click="LogOff()" style="margin: 0px">
                      註銷
                    </p></el-dropdown-item
                  >
                </el-dropdown-menu>
              </el-dropdown>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  metaInfo: {
    link: [],
  },
  components: {},
  data() {
    return {
      DoctorInformation: "",
      route: "",
      SwitchLanguage: true,
    };
  },
  mounted() {
    // 获取个人信息
    let DoctorInformation = JSON.parse(
      localStorage.getItem("DoctorInformation")
    );
    for (let i = 0; i < DoctorInformation.length; i++) {
      console.log(DoctorInformation[i].name, DoctorInformation[i].age);
    }
    this.DoctorInformation = DoctorInformation;

    var url = this.$route.name;
    if (url == "Home" || url == "caseList" || url == "patientListNew") {
      localStorage.setItem("route", JSON.stringify(this.$route.name));
      this.route = JSON.parse(localStorage.getItem("route"));
    }
    this.route = JSON.parse(localStorage.getItem("route"));
    this.SwitchLanguage =
      this.$route.name == "Home" ||
      this.$route.name == "caseList" ||
      this.$route.name == "patientListNew";
  },
  methods: {
    Jump(route) {
      this.$router.push(route);
    },
    LogOff() {
      localStorage.removeItem("token"); //清除本地储存
      localStorage.clear();
      this.$router.push("/");
    },

    gotoThisPath(language) {
      let str = this.$route.path.split("/");
      let path = "";
      if (str.length >= 3) {
        for (let i = 2; i <= str.length - 1; i++) {
          path += "/" + str[i];
        }
      } else {
        path = this.$route.path;
      }
      if (path == "/zh-en" || path == "/zh-mo") {
        path = "";
      }
      if (language == "/") {
        language = "";
      }
      if (language == path && path == "") {
        path = "/";
      }
      // 修改为使用history模式
      this.$router.push({ path: language + path });
    },
  },
};
</script>
<style scoped>
.container {
  width: 100%;
  height: 140px;

  min-width: 1248px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.bannerImg {
  width: 100%;
}
.rectangle {
  position: absolute;
  width: 100%;
  height: 60px;
  right: 0px;
  top: 80px;
  vertical-align: middle;
}
.header_inner {
  padding: 0 calc(50% - 624px);
  background: rgba(50, 62, 72, 1);
  min-width: 1248px;
  height: 60px;
}
.logoImg {
  float: left;
  height: 60px;
  font-size: 30px;
  font-weight: 700;
  letter-spacing: 0px;
  line-height: 61px;
  color: rgba(64, 255, 153, 1);
  vertical-align: top;
}
.spanDiv {
  width: calc(100% - 145px);
  /* margin-left: calc(11.61% + 144px); */
  display: inline-block;
  height: 60px;
  font-size: 15px;
  vertical-align: middle;
  clear: both;
}
.spanChoosen {
  font-family: PingFang SC;

  font-weight: 600;
  font-size: 18px;
  line-height: 60px;
  color: #ffffff;
  padding-bottom: 5px;
  border-bottom: 1px solid #ffffff;
  cursor: pointer;
}
.spanClass {
  font-family: PingFang SC;

  font-size: 18px;
  line-height: 60px;
  text-align: center;
  color: rgba(255, 255, 255, 0.51);
  margin-left: 32px;
  line-height: 40px;
  cursor: pointer;
}

.inputClass {
  width: 260px;
  height: 36px;
  background: rgba(255, 255, 255, 0.93);
  border-radius: 18px;
  /* margin-left: calc(62.75% - 144px); */
  margin-top: 12px;
  padding: 0 8px 0 40px;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAMAAABg3Am1AAAAPFBMVEUAAACEhISFhYWEhISEhISEhISHh4eBgYGDg4N/f3+EhISEhISGhoaEhISFhYWEhISDg4ODg4OEhISEhIQp+ctsAAAAE3RSTlMAXzDvn98gH0AQv3A/z6+PgFBPOylbNQAAAPJJREFUSMftlNsOgjAMhte129hEEP/3f1czjQe0HZB4YQz/Hdu+0rPb9UfyRDR1a193wriKZQ3jC15U0tL7AwM8kE/Jk1Tk0H5/AjDm+1esyKlpHwj9zMHQ/EcEQno7CmBvAgX4uOyAYuYTkM/TEbCyK4CSxcwQAzBuBrDpEWnnBOhhT8aFr4YMS0lN9vcA26Wz3qd20L2V1kEvD5uF46yGIHZrjGr9+0bzdUpkxR5P1tobqT1AfuZlAEJeGFF5WMwjqo65PaSA3JbAwACHJSLO14yPi4Trn4us5lMhFOZMRLfodcLWg5jcNoLcJkVyu35MF3jaDuxFLqWoAAAAAElFTkSuQmCC);
  background-repeat: no-repeat;
  background-position: 11.5px 5.78px;
  border: 0px;
  background-size: 24px 24px;
}

.namespan {
  display: block;
  font-family: PingFang SC;

  font-size: 14px;
  line-height: 20px;
  color: #ffffff;
  width: 110px;
  height: 20px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  text-align: center;
  margin-top: 20px;
  float: right;
}
.container .ivu-dropdown-rel {
  position: relative !important;
  height: 30px !important;
  width: 30px !important;
  -webkit-transform: translate(20px, 2px);
  transform: translate(20px, 2px);
}
.el-dropdown-menu {
  left: 1365px !important;
}
.el-popper {
  margin-top: 25px !important;
}
.el-dropdown-menu >>> .popper__arrow {
  display: none;
}
</style>