import request from '@/plugins/service'
import axios from "axios";


/**
 * 登录
 *
 */
export const Login = (data) => {
  return request({
    url: `/api/login`,
    method: 'post',
    data: data

  })
}
/**
 * 添加配送地址
 *
 */
export const ShippingAddress = (data) => {
  return request({
    url: `/api/maddress/add`,
    method: 'post',
    data: data

  })
}
/**
 * 删除配送地址
 *
 */
export const DeleteDelivery = (data) => {
  return request({
    url: `/api/maddress/delete`,
    method: 'post',
    data: data

  })
}
/**
 * 获取配送地址
 *
 */
export const GetShippingAddress = (data) => {
  return request({
    url: `/api/maddress/get?uuid=` + data,
    method: 'get',
    data: data

  })
}
/**
 * 获取医生
 *
 */
export const GetDoctor = (data) => {
  return request({
    url: `/api/getDoctor`,
    method: 'get',
    data: data

  })
}
/**
 * 发送患者信息
 *
 */
export const patientInformation = (data) => {
  return request({
    url: `/api/patient/record`,
    method: 'post',
    data: data
  })
}
/**
 * 获取患者信息
 *
 */
export const getRecord = (data) => {
  return request({
    url: `/api/patient/getRecord?uuid=` + data,
    method: 'get',
    data: data
  })
}
/**
 * 上传文件
 *
 */
export const UploadFiles = (data) => {
  return request({
    url: `/api/file/upload`,
    method: 'post',
    data: data
  })
}
/**
 * 删除文件
 *
 */
export const DeleteFile = (data) => {
  return request({
    url: `/api/file/delete?uuid=` + data,
    method: 'post',
    data: data
  })
}
/**
 * 诊断信息
 *
 */
export const diagnostic = (data) => {
  return request({
    url: `/api/patient/diagnostic`,
    method: 'post',
    data: data
  })
}
/**
 * 获取患者信息
 *
 */
export const getDiagnostic = (data) => {
  return request({
    url: `/api/patient/getDiagnostic?uuid=` + data,
    method: 'get',
    data: data
  })
}
/**
 * 治疗方案
 *
 */

export const handle = (data) => {
  return request({
    url: `/api/plan/handle`,
    method: 'post',
    data: data,
  })
}
/**
 * 资料上传
 *
 */
export const material = (data) => {
  return request({
    url: `/api/patient/material`,
    method: 'post',
    data: data
  })
}

/**
 * 获取治疗方案 同预设方案
 *
 */
export const getPreinstall = (data) => {
  return request({
    url: `/api/plan/getPreinstall?uuid=` + data,
    method: 'get',
    data: data
  })
}
/**
 * 获取病例
 *
 */
export const getList = (data) => {
  return request({
    url: `/api/patient/getList`,
    method: 'post',
    data: data
  })
}
/**
 * 删除病例
 *
 */
export const deleteCase = (data) => {
  return request({
    url: `/api/patient/deleteCase`,
    method: 'post',
    data: data
  })
}
/**
 * 提交病例
 *
 */
export const submit = (data) => {
  return request({
    url: `/api/patient/submit`,
    method: 'post',
    data: data
  })
}
/**
 * 设计师接收病例
 *
 */
export const receive = (data) => {
  return request({
    url: `/api/patient/receive`,
    method: 'post',
    data: data
  })
}
/**
 * 患者统计
 *
 */
export const statistics = (data) => {
  return request({
    url: `/api/patient/statistics?uuid=` + data,
    method: 'get',
    data: data
  })
}
/**
 * 获取病例进度
 *
 */
export const schedule = (data) => {
  return request({
    url: `/api/patient/schedule?uuid=` + data,
    method: 'get',
    data: data
  })
}
/**
 * 获取设计详单
 *
 */
export const getDesignDetails = (data) => {
  return request({
    url: `/api/patient/getDesignDetails?uuid=` + data,
    method: 'get',
    data: data
  })
}
/**
 * 审核病例
 *
 */
export const review = (data) => {
  return request({
    url: `/api/patient/review`,
    method: 'post',
    data: data
  })
}
/**
 * 提交视频方案
 *
 */
export const addPlan = (data) => {
  return request({
    url: `/api/plan/addPlan`,
    method: 'post',
    data: data
  })
}
/**
 * 获取视频的设计方案
 *
 */
export const getPlan = (data) => {
  return request({
    url: `/api/plan/getPlan?uuid=` + data,
    method: 'get',
    data: data
  })
}
/**
 * 获取生产进度
 *
 */
export const getProduction = (data) => {
  return request({
    url: `api/patient/getProduction?uuid=` + data,
    method: 'get',
    data: data
  })
}
/**
 * 生产进度修改
 *
 */
export const production = (data) => {
  return request({
    url: `/api/patient/production`,
    method: 'post',
    data: data
  })
}





//  美国官网接口
/**
 * 注册
 *
 */
export const register = (data) => {
  return request({
    url: `/api/customer/register`,
    method: 'post',
    data: data

  })
}
/**
 * 登录
 *
 */
export const logon = (data) => {
  return request({
    url: `/api/customer/login`,
    method: 'post',
    data: data

  })
}





//  英文版接口
/**
 * 登录
 *
 */
export const Loginen = (data) => {
  return request({
    url: `/api/login`,
    method: 'post',
    data: data

  })
}
/**
 * 添加配送地址
 *
 */
export const ShippingAddressen = (data) => {
  return request({
    url: `/api/maddress/add`,
    method: 'post',
    data: data

  })
}
/**
 * 删除配送地址
 *
 */
export const DeleteDeliveryen = (data) => {
  return request({
    url: `/api/maddress/delete`,
    method: 'post',
    data: data

  })
}
/**
 * 获取配送地址
 *
 */
export const GetShippingAddressen = (data) => {
  return request({
    url: `/api/maddress/get?uuid=` + data,
    method: 'get',
    data: data

  })
}
/**
 * 获取医生
 *
 */
export const GetDoctoren = (data) => {
  return request({
    url: `/api/getDoctor`,
    method: 'get',
    data: data

  })
}
/**
 * 发送患者信息
 *
 */
export const patientInformationen = (data) => {
  return request({
    url: `/api/patient/record`,
    method: 'post',
    data: data
  })
}
/**
 * 获取患者信息
 *
 */
export const getRecorden = (data) => {
  return request({
    url: `/api/patient/getRecord?uuid=` + data,
    method: 'get',
    data: data
  })
}
/**
 * 上传文件
 *
 */
export const UploadFilesen = (data) => {
  return request({
    url: `/api/file/upload`,
    method: 'post',
    data: data
  })
}
/**
 * 删除文件
 *
 */
export const DeleteFileen = (data) => {
  return request({
    url: `/api/file/delete?uuid=` + data,
    method: 'post',
    data: data
  })
}
/**
 * 诊断信息
 *
 */
export const diagnosticen = (data) => {
  return request({
    url: `/api/patient/diagnostic`,
    method: 'post',
    data: data
  })
}
/**
 * 获取患者信息
 *
 */
export const getDiagnosticen = (data) => {
  return request({
    url: `/api/patient/getDiagnostic?uuid=` + data,
    method: 'get',
    data: data
  })
}
/**
 * 治疗方案
 *
 */

export const handleen = (data) => {
  return request({
    url: `/api/plan/handle`,
    method: 'post',
    data: data,
  })
}
/**
 * 资料上传
 *
 */
export const materialen = (data) => {
  return request({
    url: `/api/patient/material`,
    method: 'post',
    data: data
  })
}

/**
 * 获取治疗方案 同预设方案
 *
 */
export const getPreinstallen = (data) => {
  return request({
    url: `/api/plan/getPreinstall?uuid=` + data,
    method: 'get',
    data: data
  })
}
/**
 * 获取病例
 *
 */
export const getListen = (data) => {
  return request({
    url: `/api/patient/getList`,
    method: 'post',
    data: data
  })
}
/**
 * 删除病例
 *
 */
export const deleteCaseen = (data) => {
  return request({
    url: `/api/patient/deleteCase`,
    method: 'post',
    data: data
  })
}
/**
 * 提交病例
 *
 */
export const submiten = (data) => {
  return request({
    url: `/api/patient/submit`,
    method: 'post',
    data: data
  })
}
/**
 * 设计师接收病例
 *
 */
export const receiveen = (data) => {
  return request({
    url: `/api/patient/receive`,
    method: 'post',
    data: data
  })
}
/**
 * 患者统计
 *
 */
export const statisticsen = (data) => {
  return request({
    url: `/api/patient/statistics?uuid=` + data,
    method: 'get',
    data: data
  })
}
/**
 * 获取病例进度
 *
 */
export const scheduleen = (data) => {
  return request({
    url: `/api/patient/schedule?uuid=` + data,
    method: 'get',
    data: data
  })
}
/**
 * 获取设计详单
 *
 */
export const getDesignDetailsen = (data) => {
  return request({
    url: `/api/patient/getDesignDetails?uuid=` + data,
    method: 'get',
    data: data
  })
}
/**
 * 审核病例
 *
 */
export const reviewen = (data) => {
  return request({
    url: `/api/patient/review`,
    method: 'post',
    data: data
  })
}
/**
 * 提交视频方案
 *
 */
export const addPlanen = (data) => {
  return request({
    url: `/api/plan/addPlan`,
    method: 'post',
    data: data
  })
}
/**
 * 获取视频的设计方案
 *
 */
export const getPlanen = (data) => {
  return request({
    url: `/api/plan/getPlan?uuid=` + data,
    method: 'get',
    data: data
  })
}
/**
 * 获取生产进度
 *
 */
export const getProductionen = (data) => {
  return request({
    url: `api/patient/getProduction?uuid=` + data,
    method: 'get',
    data: data
  })
}
/**
 * 生产进度修改
 *
 */
export const productionen = (data) => {
  return request({
    url: `/api/patient/production`,
    method: 'post',
    data: data
  })
}