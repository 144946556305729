import axios from 'axios'

import {
  Message,
  Notification,
  MessageBox
} from 'element-ui' // 这里使用了element-ui的消息提示方法，也可自行定义 

axios.defaults.headers['X-Requested-With'] = 'XMLHttpRequest'
axios.defaults.headers.post['Content-Type'] = 'text/plain;charset=UTF-8'
let service = axios.create({
  // 线上IP
  // baseURL: '/api',

  // 本地IP 
  baseURL: 'https://authenticalign.com/',
  // baseURL: 'http://172.18.80.33',

  timeout: 10000
})

// 请求拦截 可在请求头中加入token等
service.interceptors.request.use(config => {
  var data = JSON.parse(sessionStorage.getItem("token"))
  if (data) {
    let token = data;
    if (token) {
      // 在请求头中添加 Authorization 头部
      config.headers.Authorization = `Bearer ${token}`;
    }
  }
  return config;
}, error => {
  return Promise.reject(error);
});

// 响应拦截 对响应消息作初步的处理
service.interceptors.response.use(resp => {
  if (resp.data) {
    if (resp.data.code == "401") {
      MessageBox({
        message: '当前登录已失效，请重新登录',
        type: 'error'
      }).then(() => {
        localStorage.clear()
        location.href = "/"
      }).catch(() => {
        localStorage.clear()
        location.href = "/"
      })
    }
    return {
      code: resp.data.code,
      data: resp.data.data,
      msg: resp.data.message
    }
  } else {
    return resp
  }
}, error => {
  if (error.response) {
    switch (error.response.states) {
      case 400: {
        if (error.response && error.response.data && error.response.data.message) {
          Notification.error({
            title: '400错误',
            message: error.response.data.message,
            duration: 5000,
            closable: true
          })
        }
        break
      }
    }
  }
})


export default service